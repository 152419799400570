<template>
  <component :is="isCurrentComponent"></component>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    DialerDescription: () => import('@/components/services/auto_dialer/DialerDescription.vue'),
    DialerConnected: () => import('@/components/services/auto_dialer/DialerConnected.vue')
  },

  computed: {
    ...mapGetters({ servicesData: 'connectedServices/servicesData' }),

    isCurrentComponent() {
      return this.servicesData.AutoDialler === 2 ? 'DialerConnected' : 'DialerDescription';
    }
  }
};
</script>

<style></style>
